export default function (_ref, inject) {
  var router = _ref.app.router;
  if (false) {
    // inject empty gtag function for disabled mode
    inject('gtag', function () {});
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
    if (false) {
      console.debug('gtag tracking called with following arguments:', arguments);
    }
  }
  inject('gtag', gtag);
  gtag('js', new Date());
  gtag('config', 'G-2X4HNW9PTM');
  if (!false) {
    router.afterEach(function (to) {
      gtag('config', 'G-2X4HNW9PTM', {
        'page_path': to.fullPath
      });
    });
  }

  // additional accounts
}